import React, { useState } from "react";

import Card from "../UI/Card";
import ExpensesFilter from "./ExpensesFilter";
import ExpensesList from "./ExpensesList";
import ExpensesChart from "./ExpensesChart";
import './Expenses.css';

const Expenses = (props) => {

    const [chosenYear, setChosenYear] = useState('');

    const filterChangeHandler = (selectedYear) => {
        setChosenYear(selectedYear);
    }

    const filteredExpenses = props.items.filter((expense) => {
        return (expense.date.getFullYear().toString() === chosenYear) || chosenYear == '';
    });

    return(
        <div>
            <Card className="expenses">

                <ExpensesFilter initialYear={chosenYear} onChangeYear={filterChangeHandler}></ExpensesFilter>
                
                <ExpensesChart expenses={filteredExpenses}></ExpensesChart>

                <ExpensesList items={filteredExpenses}></ExpensesList>

            </Card>
        </div>
    );

}

export default Expenses;