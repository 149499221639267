import React, { useState } from "react";

import Expenses from "./components/Expenses/Expenses";
import NewExpense from "./components/NewExpense/NewExpense";



const initial_expenses = [
  {
    id: 'e1',
    title: 'Element 1',
    amount: 94.12,
    date: new Date(2019, 0, 1)
  },
  {
    id: 'e2',
    title: 'Element 2',
    amount: 194.12,
    date: new Date(2020, 1, 2)
  },
  {
    id: 'e3',
    title: 'Element 3',
    amount: 294.12,
    date: new Date(2021, 2, 3)
  },
  {
    id: 'e4',
    title: 'Element 4',
    amount: 394.12,
    date: new Date(2022, 3, 4)
  },
];



const App = () => {

  const [expenses, setExpenses] = useState(initial_expenses);

  const addExpenseHandler = (expense) => {
    setExpenses((prevExpenses) => {
      return [expense, ...prevExpenses];
    });
  }

  return (
    <div>
      
      <h2 style={{color: "rgb(200,200,200)"}}> Expense Cracker App </h2>

      <NewExpense onAddExpense={addExpenseHandler}></NewExpense>
      <Expenses items={expenses}></Expenses>

    </div>
  );
}

export default App;
