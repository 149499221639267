import React, { useState } from "react";

import './ExpenseItem.css';
import ExpenseDate from './ExpenseDate';
import Card from '../UI/Card';


const ExpenseItem = (props) => {

    const day = props.date.toLocaleString('en-US', { day: '2-digit' });
    const month = props.date.toLocaleString('en-US', { month:'long' });
    const year = props.date.getFullYear();

    const [title, setTitle] = useState('');

    const clickHandler = () => {
        setTitle("Updated !");
    };

    return (
        <li>
            <Card className='expense-item'>
                <ExpenseDate day={day} month={month} year={year}></ExpenseDate>
                <div className='expense-item__description'>
                    <h2> { props.title } / { title } </h2>
                    <div className='expense-item__price'> $ { props.amount } </div>
                </div>
                <button onClick={clickHandler}> Change Title </button>
            </Card>
        </li>
    );
}

export default ExpenseItem;